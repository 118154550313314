<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <q-form ref="editForm">
          <c-card class="cardClassDetailForm" title="안전보건활동 목표 기본정보">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  :label="popupParam.safetyHealthActivityId ? '저장' : '목표년도 생성'"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-3">
                <c-plant
                  :required="true"
                  :editable="editable && !popupParam.safetyHealthActivityId"
                  type="edit"
                  name="plantCd"
                  v-model="data.plantCd">
                </c-plant>
              </div>
              <div class="col-2">
                <c-datepicker
                  :required="true"
                  :editable="editable && !popupParam.safetyHealthActivityId"
                  type="year"
                  label="목표년도"
                  name="targetYear"
                  v-model="data.targetYear">
                </c-datepicker>
              </div>
            </template>
          </c-card>
        </q-form>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" v-if="popupParam.safetyHealthActivityId">
        <c-table
          ref="table"
          title="안전보건활동 목표/세부 추진계획"
          tableId="table"
          :columnSetting="false"
          :usePaging="false"
          :hideBottom="true"
          :filtering="false"
          :columns="grid.columns"
          :data="grid.data"
          gridHeight="auto"
        ></c-table>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: "project-activity-detail",
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        safetyHealthActivityId: '',
      }),
    },
  },
  data() {
    return {
      saveUrl: transactionConfig.sop.lim.lincense.request.insert.url,
      mappingType: 'POST',
      listUrl: '',
      detailUrl: '',
      insertUrl: '',
      updateUrl: '',
      checkUrl: '',
      saveable: false,
      editable: true,
      isSave: false,
      data: {
        plantCd: null,  // 사업장코드
        safetyHealthActivityId: '',  // 안전보건활동목표 일련번호
        targetYear: '',  // 목표년도
      },
      grid: {
        columns: [
          {
            name: 'safetyHealthActivityName',
            field: 'safetyHealthActivityName',
            label: '목표/세부 추진계획',
            align: 'center',
            style: 'width:200px',
            sortable: false,
          },
          // {
          //   label: '추진일정',
          //   align: 'center',
          //   child: [
              {
                label: '1분기',
                align: 'center',
                child: [
                  {
                    name: 'firstQuarterPlan',
                    field: 'firstQuarterPlan',
                    label: '계획',
                    align: 'center',
                    sortable: false,
                    type: 'check',
                    true: 'Y',
                    false: 'N',
                    style: 'width:60px',
                  },
                  {
                    name: 'firstQuarterResult',
                    field: 'firstQuarterResult',
                    label: '실적',
                    align: 'center',
                    sortable: false,
                    style: 'width:60px',
                    type: 'text',
                  },
                ]
              },
              {
                label: '2분기',
                align: 'center',
                child: [
                  {
                    name: 'secondQuarterPlan',
                    field: 'secondQuarterPlan',
                    label: '계획',
                    align: 'center',
                    sortable: false,
                    type: 'check',
                    true: 'Y',
                    false: 'N',
                    style: 'width:60px',
                  },
                  {
                    name: 'secondQuarterResult',
                    field: 'secondQuarterResult',
                    label: '실적',
                    align: 'center',
                    sortable: false,
                    style: 'width:60px',
                    type: 'text',
                  },
                ]
              },
              {
                label: '3분기',
                align: 'center',
                child: [
                  {
                    name: 'thirdQuarterPlan',
                    field: 'thirdQuarterPlan',
                    label: '계획',
                    align: 'center',
                    sortable: false,
                    type: 'check',
                    true: 'Y',
                    false: 'N',
                    style: 'width:60px',
                  },
                  {
                    name: 'thirdQuarterResult',
                    field: 'thirdQuarterResult',
                    label: '실적',
                    align: 'center',
                    sortable: false,
                    style: 'width:60px',
                    type: 'text',
                  },
                ]
              },
              {
                label: '4분기',
                align: 'center',
                child: [
                  {
                    name: 'fourthQuarterPlan',
                    field: 'fourthQuarterPlan',
                    label: '계획',
                    align: 'center',
                    sortable: false,
                    type: 'check',
                    true: 'Y',
                    false: 'N',
                    style: 'width:60px',
                  },
                  {
                    name: 'fourthQuarterResult',
                    field: 'fourthQuarterResult',
                    label: '실적',
                    align: 'center',
                    sortable: false,
                    style: 'width:60px',
                    type: 'text',
                  },
                ]
              },
          //   ]
          // },
          {
            name: 'resultIndex',
            field: 'resultIndex',
            label: '성과지표',
            align: 'center',
            sortable: false,
            type: 'textarea',
            style: 'width:150px',
          },
          {
            name: 'mngDeptName',
            field: 'mngDeptName',
            label: '담당부서',
            align: 'center',
            sortable: false,
            style: 'width:90px',
            type: 'text',
          },
          {
            name: 'budget',
            field: 'budget',
            label: '예산(만원)',
            align: 'center',
            sortable: false,
            style: 'width:110px',
            type: 'text',
          },
          {
            name: 'achieveRatio',
            field: 'achieveRatio',
            label: '달성율',
            align: 'center',
            sortable: false,
            style: 'width:90px',
            type: 'text',
          },
          {
            name: 'badReason',
            field: 'badReason',
            label: '실적/부진사유',
            align: 'center',
            sortable: false,
            type: 'textarea',
          },
        ],
        data: [],
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.pjm.activity.get.url;
      this.listUrl = selectConfig.pjm.activity.detail.url;
      this.insertUrl = transactionConfig.pjm.activity.mst.save.url;
      this.updateUrl = transactionConfig.pjm.activity.activity.save.url;
      this.checkUrl = selectConfig.pjm.activity.check.url;
      this.getData();
      this.getList();
    },
    getData() {
      if (this.popupParam.safetyHealthActivityId) {
        this.$http.url = this.detailUrl;
        this.$http.type = 'GET';
        this.$http.param = {safetyHealthActivityId: this.popupParam.safetyHealthActivityId};
        this.$http.request((_result) => {
          this.data = _result.data;
        },);
      }
    },
    getList() {
      if (this.popupParam.safetyHealthActivityId) {
        this.$http.url = this.listUrl;
        this.$http.type = 'GET';
        this.$http.param = {safetyHealthActivityId: this.popupParam.safetyHealthActivityId};
        this.$http.request((_result) => {
          this.grid.data = _result.data;
        },);
      }
    },
    saveData() {
      if (this.popupParam.safetyHealthActivityId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
      }
      let saveMsg = '저장하시겠습니까?';
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          this.$http.url = this.checkUrl;
          this.$http.param = {
            plantCd: this.data.plantCd,
            targetYear: this.data.targetYear,
          }
          this.$http.type = 'GET';
          this.$http.request((_result2) => {
            if (this.mappingType == 'PUT' || _result2.data === 0) {
              window.getApp.$emit('CONFIRM', {
                title: '확인',
                message: saveMsg,
                // TODO : 필요시 추가하세요.
                type: 'info', // success / info / warning / error
                // 확인 callback 함수
                confirmCallback: () => {
                  if (this.mappingType == 'PUT') {
                    let saveData = this.grid.data.filter( x => {
                      return x.editFlag == 'U'
                    });
                    this.data.activity = saveData;
                  }
                  this.data.regUserId = this.$store.getters.user.userId;
                  this.data.chgUserId = this.$store.getters.user.userId;
                  this.isSave = !this.isSave;
                },
                // 취소 callback 함수
                cancelCallback: () => {
                },
              });
            } else {
              window.getApp.$emit('ALERT', {
                title: '안내', // 안내
                message:
                '동일한 년도의 목표가 존재합니다.',
                type: 'warning', // success / info / warning / error
              });
              return;
            }
          },);
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (this.mappingType == 'POST') {
        this.popupParam.safetyHealthActivityId = result.data.safetyHealthActivityId
      }
      this.getData();
      this.getList();
    },
  }
};
</script>
